/*import TeamImg from "../../assets/people-hiking.png";
import React from "react";
import { t } from "i18next";

export default function OurTeam() {
  return (
    <div className="our-Team container">
      <h3 className="section-title-text py-5">
        <div className="title-line"></div>
        {t("our team title")}
      </h3>
      <div className="our-Team-info">
        <img src={TeamImg} alt="our team" className="img-fluid"></img>
        <h3 className="block-title py-5 m-0">{t("team secod title")}</h3>
        <p className="">{t("team secod p")}</p>
      </div>
    </div>
  );
}
*/

import TeamImg from "../../assets/people-hiking.png";
import React from "react";
import "./Main.css";
import { t } from "i18next";
import Osipov from "../../assets/osipov.png";

export default function OurTeam() {
  return (
    <div className="our-Team container">
       <h3 className="section-title-text py-5">
         <div className="title-line"></div>
         {t("our team title")}
       </h3>
       {/* <div className="our-Team-info">
         <img src={TeamImg} alt="our team" className="img-fluid"></img>
         <h3 className="block-title py-5 m-0">{t("team secod title")}</h3>
         <p className="">{t("team secod p")}</p>
       </div> */}
      <ul class="team__list"> 
            <li class="team__item">
              <img class="team__photo" src="https://scontent-iev1-1.xx.fbcdn.net/v/t1.6435-9/78432429_543530053136666_3485534950487228416_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=glu1uNPBbHcQ7kNvgFyWnHF&_nc_ht=scontent-iev1-1.xx&_nc_gid=AKlRMqgTWK3z1ZNG8CprzB4&oh=00_AYB0jhFk_mDL6rGX741uldty9ZJNwSSzMXTdRorMp1eaEA&oe=6716266E" alt="teammate photo"></img>
            {/*  <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}
              <span class="team__teammate blok-title">
              {t("vladislav")}   </span>
              <span class="team__position">
                Засновник/голова           </span>
              {/* <a href="mailto:oresta.brit@gmail.com " class="team__email">
                oresta@bonukraine.com              </a> */}
            </li>
          </ul>
      <ul class="team__list">
            <li class="team__item">
             <img class="team__photo" src="https://scontent-iev1-1.xx.fbcdn.net/v/t1.6435-9/40020784_2102209516455981_2679654212215767040_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=1d70fc&_nc_ohc=2g4ZzWJm8h0Q7kNvgEs55Mn&_nc_ht=scontent-iev1-1.xx&_nc_gid=AFwJic2f-v-g1ZuKcioMe7j&oh=00_AYDYuJxMLYlt5-95z1YuAVTBwPsL-74MdGAfeWwncVt1bg&oe=671CBCB3" alt="teammate photo"></img>
             {/* <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("ruslan")} </span>
              <span class="team__position">
                Керівник проєктів               </span>
              {/* <a href="mailto:bonusforua@gmail.com" class="team__email">
              bonusforua@gmail.com              </a> */}
            </li>
            <li class="team__item">
              <img class="team__photo" src="https://scontent-iev1-1.xx.fbcdn.net/v/t1.6435-9/129900306_1843601619127102_1031715819401416193_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=53a332&_nc_ohc=JgTZfEnftHcQ7kNvgG76Rt8&_nc_ht=scontent-iev1-1.xx&_nc_gid=A-I1BriUpqQiWasJbx7XSMc&oh=00_AYBGJh9qNXorgEDcDMt93BRCND5mZdydrS424_Jn4AdHrA&oe=671CD145" alt="teammate photo"></img>
              {/* <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("evgen")} </span>
              <span class="team__position">
                Керівник проєктів              </span>
              {/* <a href="mailto:daiva@bonukraine.com" class="team__email">
                daiva@bonukraine.com              </a> */}
            </li>
          </ul>
          <ul class="team__list">
            <li class="team__item">
             <img class="team__photo" src="https://scontent-iev1-1.xx.fbcdn.net/v/t39.30808-6/417537740_122111540798234183_1011689456328278535_n.jpg?stp=cp6_dst-jpg&_nc_cat=101&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=Oj179HGnHN8Q7kNvgFyijse&_nc_ht=scontent-iev1-1.xx&_nc_gid=Af0qsj5GPtbf-5Vqxkhzgof&oh=00_AYAmZx6wtq7dQoRRNPnwAfnhYH65K-lv0zTqjn8ZD6foog&oe=66FB0E5B" alt="teammate photo"></img>
              {/*  <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("titarenko")} </span>
              <span class="team__position">
              Керівник проєктів                </span>
            </li>
            <li class="team__item">
             
              {/*  <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}
              <img class="team__photo" src={Osipov} alt="slider prev" />
              <span class="team__teammate">
              {t("yaroslav")}  </span>
              <span class="team__position">
              Керівник проєктів        </span>
              {/* <a href="mailto:bonusforua@gmail.com" class="team__email">
              bonusforua@gmail.com              </a> */}
            </li>
            <li class="team__item">
             <img class="team__photo" src="https://scontent-iev1-1.xx.fbcdn.net/v/t31.18172-8/23000405_1477846222336658_5814619493075945494_o.jpg?_nc_cat=104&ccb=1-7&_nc_sid=53a332&_nc_ohc=iACQei65_1wQ7kNvgHF-btY&_nc_ht=scontent-iev1-1.xx&_nc_gid=ACZTNvGADcTCF1rR42Kn7Bo&oh=00_AYA-uCf-faXpG4owT82bjnS1t3B1QIC2bUC2NNOjltiBgQ&oe=671CAFC1" alt="teammate photo"></img>
             {/* <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("sergiy")} </span>
              <span class="team__position">
              Керівник проєктів                </span>
              {/* <a href="mailto:bonusforua@gmail.com" class="team__email">
              bonusforua@gmail.com              </a> */}
            </li>
          </ul>
          
    </div>
  );
}
